.footer-menu {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
margin: unset;
  
li:last-of-type{
  padding-right:unset;
  }
  &__menuItem {
    padding-left: unset;
    padding-right: 2vw;

    &:before {
      display: none; 
    }
    @include mq($until: lg) {
      padding-right: 13px;
    }
  }
  
  &__menuLink {
    font-size: rem(20px) !important;
    color: $color-font-light;
    transition: 0.3s;
    &:hover {
      color: $color-extra;
    }
    @include mq($until: lg) {
      font-size: rem(18px) !important;
    }
  }
}
