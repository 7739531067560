.button {
  color: $color-font-light;
  padding: 15px 20px;
  transition-property: color, border, background-color;
  transition-duration: .3s;
  transition-timing-function: ease-in-out;
  background-color: $color-main;
  border-radius: 5px;
  display: inline-block;
  line-height: 1;
  text-align: center;
  border: solid 1px $color-main;
  align-self: flex-start;
  cursor: pointer;

  &:hover {
    background-color: transparent;
    color: $color-main;
    border-color: $color-main;
  }

  &--secondary{
    background-color: transparent;
    color: $color-main;
    border-color: $color-main;

    &:hover {
      color: $color-font-light;
      background-color: $color-main;
      border-color: $color-main;
    }
  }
}

.link{
  font-weight: 600;
  font-size: rem(18px);
  color:$color-main;
  transition: all .4s ease-in-out;
  padding-top: 13px;
  padding-bottom: 13px;
    &--onbg{
      color:$color-main;
    }
  &:hover{
    color: aqua;
  }
  @include mq($until: lg) {
      font-size: rem(15px);
  }
  display:block;
}