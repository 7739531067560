.news-list{
    display: grid;
    flex-direction: column;
    grid-template-columns: repeat(auto-fit, minmax(40%, 1fr));
    grid-row-gap: 3vw;
    grid-column-gap: 1.3vw;
    grid-gap: 30px;
    padding-top: 5vw;
    padding-bottom: 13vw;

    @include mq($until:sm) {
        grid-template-columns: repeat(auto-fit, minmax(80%, 1fr));
    }
    &--promo{
        margin-bottom: 30px;
    }
    &__header-wrapper{
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
    &__header{
        margin-bottom: 30px;
        &--grid{
            grid-column-start: span 4;
            margin-bottom: 0;
        }
    }
    &__link{
        color: $color-main;
    }
}

.newslist-wrapper{
    // position: relative;
}

.bg{
    background-image: url("/storage/app/media/mian/news-bg.jpg");
    background-repeat: no-repeat;
    padding: unset;
    width: 100%;
    height: 100%;
    background-position: center;
    background-position-y: top;
    background-size:cover;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
}
.trade-bg{
    background-image: url("/storage/app/media/mian/buldings.jpg");
    background-repeat: no-repeat;
    padding: unset;
    width: 100%;
    height: 100%;
    background-position: center;
    background-position-y: top;
    background-size:cover;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;

    opacity: .7;
}