.subfooter{
    margin-top: 3vw;
    padding-top: 3vw;
    padding-bottom: 3vw;
    background-color: $color-main;
    &__wrapper{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .header__logo{
        filter: invert(1);
    }
    .aboutme__contactlinks a{
        color: #fff;
    }
}