.property{
  margin-top: 55px;
  transition: all .4s ease-in-out;
  &:hover{
      background-color: $color-extra-light;
  }
      &__name{
          padding: 21px 34px;
          @include mq($until: lg){
              padding:0 21px;
          }
      }
      &__pricebelt{
          background-color: $color-extra-light;
          display: flex;
          align-items: center;
          padding: 13px 34px 13px 34px;
          justify-content: space-between;
          a, span, p{
              font-size: rem(18px);
          }
          @include mq($until: lg){
              padding: 21px 13px 13px 21px;
              width: 100%;
          }
      }
      &__wrapper{
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          span{
              min-width: fit-content;
          }
          p{
              padding-right: 13px;
              min-width: fit-content;
          }
  
      }
      &__info{
          padding: 21px 34px;
          @include mq($until: lg){
              padding: 21px 13px 13px 21px;
              width: 100%;
          }
      }
  }
  