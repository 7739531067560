.przetarg{
    display: flex;
    justify-content: space-between;
    width: 100%;
    min-height: 80vh;
    // flex-direction: row-reverse;
    &__content{
       position: relative;
    }
    &__header{
        padding-top: 34px;
        font-size: rem(34px);
    }
  
    &__head-underslider{
        display: flex;
        flex-direction: column;
    }
    &__headsection{
        padding-right: 5vw;
        max-height: fit-content;
        position: relative;
        background-color: $color-extra-light;
        .property__wrapper{
            padding-bottom: 34px;
        }
        padding-left: 3vw;
       
    }

    &__informations{
        padding-left: 3vw;
        padding-right: 5vw;
        display: flex;
        .aboutme__contactlinks .contact-data__section {
            a{
                transition: all .4s ease-in-out
                &:hover{

                }
            }
            svg{
                fill: $color-main;
            }
        }
    }
    &__description{
        // padding-left: 3vw;
        // padding-right: 5vw;
        padding-top: 34px;
        position: relative;
        z-index: 2;
        background-color: #fff;
    }
    &__slidermenu{
        display: flex;
        // width: inherit;
        justify-content: space-between;
        padding-bottom: 21px;
        padding-top: 45px;
        padding: 45px 34px 42px 0;
        justify-items: center;
        min-width: 40vw;
    }

    &__slider{
       width: 44vw;
       height: 30vw;
       overflow: hidden;
       @include mq($until: xl){
        height: 40vw;
        }
    }    
    .slick-initialized .slick-slide{
        // width: 44vw;
        overflow: hidden;
        height: 32vw;
        width: 55vw;
        align-items: center;
        justify-content: center;
        display: flex;
        @include mq($until: xl){
            height: 40vw;
            width: 70vw;
        }
    }

    .slick-dotted.slick-slider {
        margin-bottom: unset;
    }

    // &__slider{
    //    height: 30vw;
    //    overflow: hidden;
    //     .slick-slide img{
    //     opacity: 0.3;
    //     transition: opacity .1s ease-in-out;
    //     height: 40vw;
    //     object-fit: cover;
    //     @include mq($until: md){
    //         height: 50vw;
    //     }
    //     @include mq($until: sm){
    //         height: 65vw;
    //     }
    //     }
    //     .slick-current img{
    //     opacity: 1;
    //     }
    // }
    &__slide{
        position: relative;
        outline: none;
      
        .apartment__photo{
            background-position: center;
        }
    }
    &__arrows{
        position: relative;
        width: 100%;
        margin: 0 auto;
        bottom: 6px;
        @include mq($until: md){
        bottom: 5px;
        }
        
    }
    &__sliderwrap{
        background-color: $color-extra-light;
        display: flex;
        flex-direction: column;
       justify-content: center;
       margin-bottom: auto;
       position: relative;
       .news__date{
           margin-bottom: 0;
       }
       .przetargi__picture{
           position: absolute;
           z-index: -1;
           width: inherit;
       }
    }
}

.catalog-list{
    max-width: 50%;
    @include mq($until: xl){
        max-width: 70%;
    }
    @include mq($until: lg){
        max-width: 100%;
    }
}