.news-card{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    background-color: #fff;
    padding: 21px ;
    transition: all .4s ease-in-out;
    &:hover{
        background-color: $color-main;
    }

    &__header{
        font-size: 1.2rem;
        margin-top: 15px;
        @include mq($until:sm) {
            // align-items: center;
            // text-align: center;
        }

    }
    &__more{
        margin-top: auto;
        padding-top: 15px;
        @include mq($until:sm) {
            align-items: center;
            text-align: center;
        }
    }
    &__excerpt{
        @include mq($until:sm) {
            // align-items: center;
            // text-align: center;
        }
    }

    &:hover{
        .news-card{
            background-color: $color-main;
        }
        .news-card__header{
            color: #fff;
        }

        .news-card__excerpt{
            color: #fff;
        }
        .news-card__more{
            color: #fff;
        
        }
 
    }

}
