.top{
    display: flex;
    position: relative;
    padding-bottom: 13vw;
    @include mq($until: xs) {
        flex-direction: column;
    }
    &__header h1{
        font-size: rem(30px);
        @include mq($until: xl) {
            font-size: rem(24px);
        }
        @include mq($until: lg) {
            font-size: rem(21px);
        }
    }
    &__text p{
        @include mq($until: xl) {
            font-size: rem(14px);
        }
    }
    &__wrapper{
        display: flex;
        @include mq($until: md) {
           flex-direction: column;
        }
    }
    &__pictures{
        position: relative;
        width: 100%;
    }
    &__main-image{
        min-width: 50vw;
        z-index: 1;
        position: relative;
        @include mq($until: md) {
           position: static;
         }
    }
    &__bgimage{
        position: absolute;
        top: -9vw;
        margin-left: auto;
        width: 65vw;
       right: 0;
       z-index: -1;
        @include mq($until: xl) {
            width: 70vw;
            top: -13vw;
        }
        @include mq($until: lg) {
            top: -120px;
        }
        @include mq($until: md) {
            display: none;
        }
    }
    &__infobox{
        min-width: 50vw;
        padding: 8vw 8vw 8vw 5vw;
        @include mq($until: xxl) {
            padding: 5vw 8vw 8vw 5vw;
        }
        @include mq($until: lg) {
            padding: 21px 8vw 8vw 5vw;
        }
        @include mq($until: md) {
            padding: 34px 8vw 5vw 5vw;
            background-color: $color-extra-light;
        }
    }
    &__contents{
        position: relative;
        z-index: 1;
        max-width: 21vw ;
        @include mq($until: xxl) {
            max-width: 34vw ;
        }
        @include mq($until: md) {
            max-width: 55vw ;
        }
        @include mq($until: sm) {
            max-width: 60vw ;
        }
        @include mq($until: xs) {
            max-width: unset;
            text-align: center;
        }
    }
    &__linkbar{
        background-color: $color-main;
        position: absolute;
        z-index: 2;
        display: flex;
        align-items: center;
        justify-content: space-around;
        width: 60vw;
        right: 10vw;
        padding: 2vw 3vw 2vw 3vw;
        bottom: calc(100% - 38vw);
        @include mq($until: xxl) {
            width: 65vw; 
        }
        @include mq($until: xl) {
            width: 70vw; 
            right: 8vw;
        }
        @include mq($until: lg) {
            bottom: calc(100% - 40vw);
            width: 80vw; 
        }
        @include mq($until: md) {
            position: static;
            flex-direction: column;
            text-align: center;
            width: 34vw;
            height: inherit;
            padding: 2vw 3vw 2vw 3vw;
          }
        @include mq($until: xs) {
               width: 100%;
               height:  210px;
        }

        a{
            color: #fff;
            display:block;
            @include mq($until: xxl) {
                font-size: rem(16px);
            }
            @include mq($until:xl) {
                font-size: rem(14px);
            }
        }
        .separator{
            height: 3vw;
            width: 1px;
            margin-right: 21px;
            margin-left: 21px;
            background-color: rgba(255, 255, 255, 0.262); 
            @include mq($until: md) {
               display: none;
            }
        }
    }
}


.przetargi{
    &__wrapper {
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: baseline;
        // min-width: 50vw;
    }
    &__buton{
        display: block;
        text-decoration: underline;
        padding-left: 21px;
        font-style: italic;
        font-weight: 400;
        font-size: rem(22px);
    }
    &__heading{
        display: flex;
        align-items: center;
    }
    &__content{
        display: flex;
    }
    &__list{
        width: 100%;
       margin-bottom: 5vw;
       margin-top: 5vw;
        @include mq($until:md) {

            max-width: 95%;

            position: relative;
            z-index: 1;
        }
    }
    &__picture{
        margin-left: auto;
        margin-top: auto;
        width: 46vw;
        height:auto;
        @include mq($until:lg) {
            width: 41vw;
        }
        @include mq($until:md) {
            width: 100%;
            align-self: center;
            position: absolute;
            z-index: -1;
            min-height: unset;
            opacity: .05;

        }
    }
    @include mq($until:md) {
        flex-direction: column;    
        position: relative;
     }
}
.aboutme{
    background-color: $color-main;
    color: #fff;
    &__wrapper{
        background-color: $color-main;
        max-width: 55vw;
        min-height: 60vh;
        display: flex;
        justify-content: center;
        flex-direction: column;
        @include mq($until:xl) {
            max-width: 60vw;
        }
        @include mq($until:md) {
            max-width: unset;
            background-color: $color-main-transparent;
        }
    }
    &__content {
        padding-right: 5vw;
        padding-top: 5vw;
        padding-bottom: 5vw;
        h2, h3, h4, p, span, a{
            color: #FFF;
        }
        @include mq($until:sm) {
            padding-top: 55px;
            padding-bottom:55px;
        }
        
    }
    &__icons{
        width: 100%;
        padding-right: 2vw;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(13%, 1fr));
        @include mq($until:xs) {
            padding-top: 34px;
            pointer-events: 34px;
            grid-template-columns: 1fr 1fr;
        }
    }
    &__item{
        padding: 5vw 21px 21px 21px;
        display: flex;
        flex-direction: column;
        align-items: center;
    
        svg{
            fill: rgba(255, 255, 255, 0.513);
            width: 3vw;
            height: 3vw;
            @include mq($until:lg) {
                width: 60px;
                height: 60px;    
            }
        }
        span{
           margin-top: 21px;
           margin-bottom: 13px;
           text-align: center;
           padding: unset;
        }
    }
    &__line{
        width: 100%;
        height: 1px;
        background-color: rgba(255, 255, 255, 0.513);;
    }
    &__picture{
        background-image: url("/storage/app/media/mian/about2.jpg");
        background-repeat: no-repeat;
        background-size:cover;
        background-position-x:5vw;
        background-position-y:-8vw;

        @include mq($until:xxl) {
            background-position: 80px;
        }

        @include mq($until:lg) {
            background-position:left;
        }
        @include mq($until:md) {
            background-position-x:right;
            background-position-y:0;
        }
        // @include mq($until:sm) {
        //     background-position-x:-50px;
        // }
        @include mq($until:xs) {
            background-position:lright;
                        background-position-x:-580px;
        }
    }
    &__contactlinks{
        display: flex;
        align-items: baseline;
        flex-wrap: wrap;
       
        .contact-data{
           &__section{
            padding-top: 21px;
               align-items: center;
               svg{
                transition: all .4s ease-in-out;
                fill: rgba(255, 255, 255, 0.513);
               }
           }
           &__icon{
            transition: all .4s ease-in-out;
            &:hover > a { 
                    .svg-icon{
                        fill: $color-extra ;
                    }
            }
           }
           &__content{
            transition: all .4s ease-in-out;
            &:hover > a { 
                    color: $color-extra;
            }
                &--phones{
                    padding-right: 55px;
                }
           
                &--emails{
                    padding-right: 55px;
                }
           }
        }
        
    }
}


.newsection{
    padding-top: 8vw;
    background-image: url("/storage/app/media/mian/news-bg.jpg");
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    background-position: center;
    background-position-y: top -10vw;
    background-size:cover;

}

.full-footer{
    position: relative;
    padding-top: 8vw;
    &__data{
        .contact-data__section{
            padding-bottom: 34px;
        }
    }
    &__links-wrapper{
        .link{
            padding-top: 34px;
            @include mq($until:lg) {
                padding-top: 13px;
            }
        }
    }
    &__logo{
        display: inline-block;
        margin-bottom: 5vw;
        width: 270px;
        @include mq($until:lg) {
            width: 220px;
        }
    }

    &__data-wrapper{
        margin-bottom: 3vw;
    }

    &__bluebelt{
        background-color:$color-main;
        padding-top: 34px;
        padding-bottom: 34px;
        a, span, p, svg {
            color: #fff;
        }

        display: flex;
        align-items: flex-end;
        justify-content: space-between;
             min-width: 60vw;
        @include mq($until:sm) {
            flex-direction: column ;
            align-items: start;
        }
    }
    &__map{
        position: absolute;
        right: 0;
        bottom: 100px;
        filter: grayscale(100%);
        width: 60vw;
        min-height: 32vw;
        height: 75%;
        @include mq($until:sm) {
            // width: 55vw;
            width: 100vw;
            height:47vw;
            position: static;
            z-index: 10;
            .map{
                height:80vw;
            }
        }
    }
    &__bg{
        min-width: 60vw;
        height: 36vw;
        position:absolute;
        z-index: -1;
        left: 0px;
        top: 0;
        @include mq($until:lg) {
            height: 46vw;
        }
        @include mq($until:md) {
           width: 100%;
           height: 100%;
        }

        
    }
    &__pict{
        background-image: url(/storage/app/media/mian/fot-bg.jpg);
        background-repeat: no-repeat;


        width: 100%;
        height: 100%;
        background-size: cover;
    }
    &__menu{
        .header__menuList {
            flex-direction: row;
         
            a{ 
                padding-left: 21px;
                @include mq($until:sm) {
                    padding-right: 21px;
                    padding-left: unset;
                }
            }
            @include mq($until:md) {
               font-size: rem(18px);
               align-items: baseline;
            }
            @include mq($until:md) {
               display: none;
            }
        }
    }
}